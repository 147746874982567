import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import CarouselBg from "../components/CarouselBg"
import Pagination from "../components/Pagination"
import Image from "gatsby-image"
import has from "lodash.has"
import { css } from "@emotion/core"
import { Helmet } from "react-helmet"
import slugify from "../util/slugify"

export default function Services({ data, pageContext }) {
  const { current, pages, fieldValue: tagValue } = pageContext
  function clickHandler(e) {
    e.preventDefault()
    console.log(e.target.hash.slice(1, -1))
    const id = e.target.hash && e.target.hash.slice(1)
    const el = document.getElementById(id)
    console.log(el)
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }
  return (
    <Layout>
      <Helmet>
      <title>
         Services | Softful - Software company Auckland NZ
        </title>
        </Helmet>
        <div>
        <CarouselBg>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-right in-pic-height">
                <div className="in-text-holder">Services</div>
                <div
                  className="in-pic-1 in-pic-height"
                  style={{ float: "right" }}
                >
                  <Image
                    style={{ height: "100%" }}
                    fluid={data.in_pic_1.sharp.fluid}
                  />
                </div>
              </div>
            </div>
          </div>
        </CarouselBg>
      <div className="breadcrums">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Link to="/">Home</Link> - Services
            </div>
          </div>
        </div>
      </div>
      
      <div className="content-holder">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
            {data.services.group.map((serviceGroup) => {
                let topSlug = slugify(serviceGroup.fieldValue);
                return (
                    <div>
                      <h1 id={topSlug}>{serviceGroup.fieldValue}</h1>

                    {serviceGroup.nodes.map((subgroup)=>{
                      return (
                        <div>
                     
                       <h2 className="subheader">{subgroup.frontmatter.title}</h2>
                    
                    <p>
                    {subgroup.frontmatter.excerpt}
                  <br></br>
                   <Link                      
                   to={`/services/${topSlug}/${subgroup.frontmatter.slug}`}
                   className="sublink">{subgroup.frontmatter.clicktext}</Link>
                   </p>
                  </div>
                      )
                    } )}
                <div class="clear"></div>
                <div class="divider"></div>
                </div>
                 
                )
              })}
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    in_pic_1: file(publicURL: { regex: "/in_pic_1.png/" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 641) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    services: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/posts/services/"}}) {
      group(field: frontmatter___servicecat1) {
        fieldValue
        nodes {
          id
          frontmatter {
            title
            slug          
            excerpt
            clicktext
          }
        }
      }
    }
    
    headers: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/posts/services/"}}) {      
      group(field: frontmatter___servicecat1) {
        fieldValue
      }
    }    
  }
`

